import React, { useEffect, useState } from "react";
import BetaBlue from "../../Assets/Images/BetaBlue.svg"
import "./BetaLabel.css"

function BetaLabelBlue() {

  return (
    <img
      src={BetaBlue}
      alt='beta'
      className='betaLabel'
    />
  );
}

export default BetaLabelBlue;

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Testimonial1 from "../../../Assets/Images/CEO.svg";
import Testimonial2 from "../../../Assets/Images/female-doctor-nurse.svg";
import Testimonial3 from "../../../Assets/Images/male.svg";
import Testimonial4 from "../../../Assets/Images/child.svg";
import Testimonial5 from "../../../Assets/Images/young-students.svg";
import apostrophe from "../../../Assets/Images/apostrophe.png";
function CarouselFadeExample() {
  return (
    <div>
      <Carousel
        showThumbs={false}
        showStatus={false}
        // infiniteLoop
        // emulateTouch
        // autoPlay
        useKeyboardArrows
        transitionTime={1000}
        // axis="vertical"
        // selectedItem={1}
      >
        <div className="slide-holder">
          <div className="col-md-12 col-lg-8">
            <div className="row w-100 d-flex align-items-center justify-content-center column-reverse">
              <div className="col-md-12 col-lg-4">
                <img
                  className="img-fluid testimonial-img"
                  src={Testimonial1}
                  alt="First slide"
                />
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="text-container">
                  <h3>Testimonials</h3>
                  <span>See what people are saying</span>
                  <p className="mt-4">
                    <img
                      className="d-block apostrophe mb-3"
                      src={apostrophe}
                      alt="Second slide"
                    />
                    It helps me fall asleep at night. It is Indeed relaxing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slide-holder">
          <div className="col-md-12 col-lg-8">
            <div className="row w-100 d-flex align-items-center justify-content-center column-reverse">
              <div className="col-md-12 col-lg-4">
                <img
                  className="img-fluid testimonial-img"
                  src={Testimonial2}
                  alt="First slide"
                />
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="text-container">
                  <h3>Testimonials</h3>
                  <span>See what people are saying</span>
                  <p className="mt-4">
                    <img
                      className="d-block apostrophe mb-3"
                      src={apostrophe}
                      alt="First slide"
                    />
                    I’ve made a habit of using BreathConductor before bed.
                    Beyond setting me up for restful sleep, its impact has
                    upleveled my life.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slide-holder">
          <div className="col-md-12 col-lg-8">
            <div className="row w-100 d-flex align-items-center justify-content-center column-reverse">
              <div className="col-md-12 col-lg-4">
                <img
                  className="img-fluid testimonial-img"
                  src={Testimonial3}
                  alt="Third slide"
                />
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="text-container">
                  <h3>Testimonials</h3>
                  <span>See what people are saying</span>
                  <p className="mt-4">
                    <img
                      className="d-block apostrophe mb-3"
                      src={apostrophe}
                      alt="First slide"
                    />
                    I feel comfortable when performing the exercises after a
                    long tiring day. I also use the exercises to assist me with
                    emotional situations I face in my daily life.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slide-holder">
          <div className="col-md-12 col-lg-8">
            <div className="row w-100 d-flex align-items-center justify-content-center column-reverse">
              <div className="col-md-12 col-lg-4">
                <img
                  className="img-fluid testimonial-img"
                  src={Testimonial4}
                  alt="Fourth slide"
                />
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="text-container">
                  <h3>Testimonials</h3>
                  <span>See what people are saying</span>
                  <p className="mt-4">
                    <img
                      className="d-block apostrophe mb-3"
                      src={apostrophe}
                      alt="First slide"
                    />
                    When I am nervous, the exercises help me relax and be
                    happier.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slide-holder">
          <div className="col-md-12 col-lg-8">
            <div className="row w-100 d-flex align-items-center justify-content-center column-reverse">
              <div className="col-md-12 col-lg-4">
                <img
                  className="img-fluid testimonial-img"
                  src={Testimonial5}
                  alt="Fifth slide"
                />
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="text-container">
                  <h3>Testimonials</h3>
                  <span>See what people are saying</span>
                  <p className="mt-4">
                    <img
                      className="d-block apostrophe mb-3"
                      src={apostrophe}
                      alt="First slide"
                    />
                    These breathing sessions make my classmates and I calmer and
                    have happy dreams.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
}

export default CarouselFadeExample;

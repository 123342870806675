import "./Banner.css";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import aos from "aos";
import bannerScreen from "../../../Assets/Images/banner_img.png";
import { NavLink } from "react-router-dom";
import { deleteAge } from "../../../actions/getsurvey";
import { deleteExperience } from "../../../actions/getexperiense";
import { deleteLanguage } from "../../../actions/getlanguage";
import { deleteGender } from "../../../actions/getgender";
import { deleteToken } from "../../../actions/currentUser";
import { logout } from "../../../actions/currentUser";
import { useDispatch } from "react-redux";
import { deleteExcerciseList } from "../../../actions/getExcercise";
import { removeTempData } from "../../../actions/tempData";
import { deleteDefaultList, deleteNewDefaultList } from "../../../actions/defaultList";
import { Reactplayers } from "../../../commonfunction";

const Banner = () => {
  const[show,setShow]=useState(false)
  useEffect(() => {
    //Hook is use to apply animation on scroll
    aos.init({ duration: 1500 }); // initialise AOS
    aos.refresh();
  }, []);
  const dispatch = useDispatch();
  function randomcheck() {
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(removeTempData())
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList())
  }
  return (
    <>
    <div className="w-100 lendingPage">
      <section className="bannerArea">
        <div className=" banner_2" style={{

          zIndex: "99999"
        }}>
          <Container>
            <Row>
              <Col lg={5} md={12}>
                <div
                  data-aos="fade-right"
                  data-aos-delay="500"
                  data-aos-anchor-easing="ease-in"
                  data-aos-once="true"
                >
                  <div className="bannerContent">
                    <h1>BreathConductor™</h1>
                    <h1>
                      {/* Mental Fitness On-Demand */}Anxiety Relief On-Demand
                    </h1>
                    <p>
                      Relieve anxiety, manage stress, improve sleep with personalized self-guided breathing sessions that harness music’s power to drive synchrony
                    </p>
                   
                      <button onClick={()=>{setShow(true);randomcheck()}} style={{
                        border: "2px solid #f5f0f0",
                        maxWidth: "231px",
                        minHeight: "54px",
                        width: "100%",
                        borderRadius: "50px",
                        color: "#edf1f3",
                        fontSize: "21px",
                        marginTop:"16px"
                      }}>
                        About BreathConductor
                      </button>
                    
                  </div>
                </div>
              </Col>
              <Col lg={7} md={12}>
                <div className="bannerImage">
                  <div
                    data-aos="fade-up"
                    data-aos-anchor-easing="ease-in"
                    data-aos-once="true"
                  >
                    <img src={bannerScreen} alt="banner" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </section>
    </div>
   <Reactplayers shows={show} setShows={setShow}/>
    </>
  );
};
export default Banner;

import "./HomeFooter.css";
import "bootstrap/dist/css/bootstrap.css";
import { Col, Container, Row } from "react-bootstrap";
import SublyApi from "../../helpers/Api";
import { useEffect, useState } from "react";
const HomeFooter = () => {
  const [url, seturl] = useState();
  //it's api term & privacy policy work 
  useEffect(() => {
    async function terms() {
      const term = await SublyApi.termconditions();
      if (
        term &&
        term.data.error_type === "USER_NOT_FOUND" &&
        term.data.status_code === 400
      ) {
        // Toast.fire({
        //   icon: "error",
        //   title: historyData.data.message,
        // });
      }
      else {
        term && seturl(term.data.urls)
      }
    }
    terms();
  }, []);
  return (
    <section className="footerSection">
      <Container>
        <div className="FooterContain">
          <Row>
            <Col lg={5} md={6} sm={6}>
              <div className="footerLeft">
                <p>
                  <a href="https://muviklabs.io" target="blank">
                    © Muvik Labs LLC
                  </a>
                  <span className="footerrights">&nbsp;{(new Date).getFullYear()}. All Rights Reserved</span>
                </p>
              </div>
            </Col>
            <Col lg={7} md={6} sm={6}>
              <ul className="footerRightLink">
              <li>
                  <a href="mailto:contact@breathconductor.com"   target="blank">
                  Provide Feedback
                  </a>
                </li>
                <li className="footerdiv">
                  <p >|</p>
                </li>
                <li>
                  <a href={url ? url.privacy_policy : ""} target="blank">
                    Privacy Policy
                  </a>
                </li>
                <li className="footerdiv">
                  <p >|</p>
                </li>
                <li>
                  <a href={url ? url.terms_of_use : ""} target="blank">
                    Terms & Conditions
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};
export default HomeFooter;

import "./HomeHeader.css";
import "animate.css/animate.min.css";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import logo from "../../Assets/Images/Logo.svg";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../commonFile";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, useHistory } from "react-router-dom";
import { deleteAge } from "../../actions/getsurvey";
import { deleteExperience } from "../../actions/getexperiense";
import { deleteLanguage } from "../../actions/getlanguage";
import { deleteGender } from "../../actions/getgender";
import { deleteToken } from "../../actions/currentUser";
import { logout } from "../../actions/currentUser";
import { useDispatch } from "react-redux";
import { deleteExcerciseList } from "../../actions/getExcercise";
import { removeTempData } from "../../actions/tempData";
import { deleteDefaultList, deleteNewDefaultList } from "../../actions/defaultList";
import BetaLabelBlue from "../../Components/Beta/BetaLabelBlue"

const HomeHeader = () => {
  const [navBar, setNavBar] = useState(false);
  const dispatch = useDispatch()
  const history =useHistory();
  const changeBackground = () => {
    //function is used to set the header sticky with animation
    if (window.scrollY > 60) {
      setNavBar(true);
    } else {
      setNavBar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);
  async function getearly() {
    const emailvalue = await document.getElementById("email").value;
    var formdata = new FormData();
    formdata.append("email", emailvalue);
    await SublyApi.earlyaccess(formdata).then(async (responsejson) => {
      if (responsejson.data.status_code === 400) {
        Toast.fire({
          icon: "error",
          title: `${responsejson.data.message}`,
        });
      } else {
        Toast.fire({
          icon: "success",
          title: `${responsejson.message}`,
        });
      }
    });
  }

  function randomcheck() {
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deleteExcerciseList())
    dispatch(deleteExperience());
    dispatch(removeTempData())
    dispatch(logout());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList())
    dispatch(deleteToken());
  }
  return (
    <section
      className={navBar ? "navBar navbarFixed" : "navBar"}
      style={{ zIndex: "9999999", paddingTop: "0" }}
    >
      <Container fluid className="px-0">
        <div className="headerInner">
          <div className="col-lg-9 d-flex align-items-start align-items-lg-center">
          <div className="beta">
            <BetaLabelBlue/>
          </div>
            <div className="headerLogo">
              <a href="javascript:void(0);">
                <img src={logo} alt="BreathNew" />
              </a>
            </div>
            <Navbar bg="light" expand="lg" className="headerLogo1">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="#aboutus">About Us</Nav.Link>
                  <Nav.Link href="#Libraries">Libraries</Nav.Link>
                  <Nav.Link href="#Testimonials">Testimonials</Nav.Link>
                  <Nav.Link href="#RespireAr">RespireAR</Nav.Link>
                  <Nav.Link href="#Contactus">Contact Us</Nav.Link>
                  <Nav.Link onClick={()=>history.push("/login")}>Login</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
          <div className="col-lg-3 d-flex align-items-start align-items-lg-center justify-content-left justify-content-xl-center ">
            <form className=" align-items-start">
              <div className="d-flex py-3">
                <div className="create_btn">
                  <NavLink
                    exact
                    to="/age-question"
                    className="button button1 a-link"
                    // href="https://dev.breathconductor.com/breathNew/signup"
                    onClick={randomcheck}
                  >
                    Create Account
                  </NavLink>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default HomeHeader;
